import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import SuccessUpdate from '@components/SuccessUpdate/SuccessUpdate';

const successUpdate = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const sessionId = params.get('id');

  return (
    <Layout location={location} map itinerari>
      <Seo
        title="Stiamo concludendo il tuo pagamento! - Tiziano Bieller Sport"
        lang="it"
      />
      <SuccessUpdate sessionId={sessionId} />
    </Layout>
  );
};

export default successUpdate;
