import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import ClipLoader from 'react-spinners/ClipLoader';

import regolamento from '@static/pdf/regolamento-noleggio-ebike.pdf';

import { retrieveCheckout, updateAvailabilities } from '@api';

const SuccessUpdate = ({ sessionId }) => {
  const regolamentoLink =
    typeof window !== 'undefined'
      ? `${window.location.origin}${regolamento}`
      : null;
  const itinerariLink =
    typeof window !== 'undefined'
      ? `${window.location.origin}/itinerari`
      : null;

  const updateFirestore = session => {
    const keys = Object.keys(session.metadata);
    if (keys.length > 0) {
      const updates = [];
      keys.forEach(k => {
        const value = session.metadata[k];
        const valueArray = value.trim().split('###');
        const id = valueArray[0];
        const type = valueArray[1];
        const quantity = parseInt(valueArray[2], 10);
        updates.push({ id, type, quantity });
      });
      updateAvailabilities(updates);
      navigate(`/success?id=${sessionId}`);
    }
  };

  const getSession = async id => {
    const checkout = await retrieveCheckout(
      id,
      true,
      regolamentoLink,
      itinerariLink
    );
    if (checkout && checkout.data) {
      updateFirestore(checkout.data.session);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    getSession(sessionId);
  }, [sessionId]);
  /* eslint-enable */

  return (
    <div style={{ padding: '100px 0', textAlign: 'center' }}>
      <ClipLoader color="#093f98" loading />
      <div style={{ fontWeight: 'bold', marginTop: '4px', color: '#0c53c8' }}>
        Attendi un istante...
        <br />
        Stiamo concludendo la tua prenotazione!
      </div>
    </div>
  );
};

SuccessUpdate.propTypes = {
  sessionId: PropTypes.string,
};

export default SuccessUpdate;
